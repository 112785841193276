import React, { useEffect, useRef } from "react";
import L from "leaflet";
import { Map, TileLayer } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import "./MapView.css";
import axios from "axios";

import mapa from "../alcaldiascdmxgeo.json";
delete L.Icon.Default.prototype._getIconUrl;

//icono de marcador

const customMarker = new L.icon({
  iconUrl:
    "https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-violet.png",
  shadowUrl:
    "https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png",
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowSize: [41, 41],
});

// Funcion para desplegar el mapa y los marcadores
function MapView(props) {
  const mapRef = useRef();
  const layerRef = useRef(L.featureGroup());
  const poligonRef = useRef(L.featureGroup());
  //console.log("test prop", props.datos);
  //console.log("test prop instancia", props.datos.instancia);

  useEffect(() => {
    const { current = {} } = mapRef;
    const { leafletElement: map } = current;

    if (!map) return;
    if (props.datos ==0)  {
      axios
      .get(
        "https://www.inventariosedes.semujeres.cdmx.gob.mx/api/sedes/0/0/0/0/0" 
      )
      .then((response) => {
        console.log("datos mapa", response.data);
        response.data.map((el) => {
          //var marker = L.marker([el.latitud, el.longitud], { icon: customMarker });
          var descripcion = el.descripcion;
          var calle = el.calle;
          var num_exterior = el.num_exterior;
          var num_interior = el.num_interior;
          var referencia = el.referencia;
          var telefono_uno = el.telefono_uno;
          var extension_uno = el.extension_uno;
          var telefono_dos = el.telefono_dos;
          var extension_dos = el.extension_dos;
          var dias_atencion = el.dias_atencion;
          var horario_ini = el.horario_ini;
          var horario_fin = el.horario_fin;
          var colonia = el.colonia;
          var instanciadesc = el.instanciadesc;
          var municipio = el.municipio;
          var servicios = el.servicios;
          var telefono_uno_llamada = "";
          var telefono_dos_llamada = "";
          if (servicios == null) {
            servicios = "";
          } else
            var servicios_ = servicios.replaceAll(",", "<br>&nbsp;-&nbsp;");

          //var servicio=el.servicios.toUpperCase();

          if (num_exterior !== "") num_exterior = ", No. " + num_exterior;
          if (num_interior !== "") num_interior = ", Int. " + num_interior;
          if (extension_uno !== "") {
            extension_uno = " Ext." + extension_uno;
          }
          if (telefono_dos !== "") telefono_dos = "  " + telefono_dos;
          if (extension_dos !== "") {
            extension_dos = " Ext. " + extension_dos;
          }
          if (colonia !== "") colonia = ", Colonia " + colonia;
          if (municipio !== "") municipio = ", Alcaldia " + municipio;
          if (horario_ini !== "")
            horario_ini = "De " + horario_ini + " a " + horario_fin;
          var domicilio =
            "<p class='titulo'><b>" +
            instanciadesc  +
            "</b></font></p>\n\
      <p align='center'  class='texto'>" +
            descripcion +
            "</p>\n\
      <p class='titulo'><b>DOMICILIO:</b></p>\n\
      <p class='texto' >" +
            calle +
            num_exterior +
            num_interior +
            colonia.toLowerCase() +
            municipio.toLowerCase() +
            " <br>" +
            referencia +
            "</p>\n\
      <p class='titulo'><b>TELÉFONOS:</b></p>\n\
      <p><a href='tel:+" +
            telefono_uno +
            "'>" +
            telefono_uno +
            "</a>" +
            extension_uno +
            "</p>\n\n<p><a href='tel:+" +
            telefono_dos +
            "'>" +
            telefono_dos +
            "</a>" +
            extension_dos +
            "</a></p>\n\
      <p class='titulo'><b>HORARIOS DE ATENCIÓN:</b></p>\n\
      <p>" +
            dias_atencion +
            "</p>\n\
      <p>" +
            horario_ini +
            "</p>\n\
      <p class='titulo'><b>SERVICIOS:</b></p>\n\
      <p class='texto'>&nbsp;-&nbsp;" +
            servicios_.toLowerCase() +
            "</p>\n\
      ";

          L.marker([el.latitud, el.longitud], { icon: customMarker })
            .addTo(layerRef.current)
            .bindPopup(domicilio);

          console.log("layer ref", layerRef.current);
        });
      });
    } else{
    //Se agregan los marcadores
    axios
    .get(
      "https://www.inventariosedes.semujeres.cdmx.gob.mx/api/sedes/" +
      props.datos.instancia +
      "/" +
      props.datos.tipoSede +
      "/" +
      props.datos.alcaldia +
      "/" +
      props.datos.tipoServicio +
      "/" +
      props.datos.servicio 
    )
      .then((response) => {
        console.log("datos mapa", response.data);
        response.data.map((el) => {
          //var marker = L.marker([el.latitud, el.longitud], { icon: customMarker });
          var descripcion = el.descripcion;
          var calle = el.calle;
          var num_exterior = el.num_exterior;
          var num_interior = el.num_interior;
          var referencia = el.referencia;
          var telefono_uno = el.telefono_uno;
          var extension_uno = el.extension_uno;
          var telefono_dos = el.telefono_dos;
          var extension_dos = el.extension_dos;
          var dias_atencion = el.dias_atencion;
          var horario_ini = el.horario_ini;
          var horario_fin = el.horario_fin;
          var colonia = el.colonia;
          var instanciadesc = el.instanciadesc;
          var municipio = el.municipio;
          var servicios = el.servicios;
          var telefono_uno_llamada = "";
          var telefono_dos_llamada = "";
          if (servicios == null) {
            servicios = "";
          } else
            var servicios_ = servicios.replaceAll(",", "<br>&nbsp;-&nbsp;");

          //var servicio=el.servicios.toUpperCase();

          if (num_exterior !== "") num_exterior = ", No. " + num_exterior;
          if (num_interior !== "") num_interior = ", Int. " + num_interior;
          if (extension_uno !== "") {
            extension_uno = " Ext." + extension_uno;
          }
          if (telefono_dos !== "") telefono_dos = "  " + telefono_dos;
          if (extension_dos !== "") {
            extension_dos = " Ext. " + extension_dos;
          }
          if (colonia !== "") colonia = ", Colonia " + colonia;
          if (municipio !== "") municipio = ", Alcaldia " + municipio;
          if (horario_ini !== "")
            horario_ini = "De " + horario_ini + " a " + horario_fin;
          var domicilio =
            "<p class='titulo'><b>" +
            instanciadesc.toUpperCase() +
            "</b></font></p>\n\
      <p align='center' class='texto'>" +
            descripcion +
            "</p>\n\
      <p class='titulo'><b>DOMICILIO:</b></p>\n\
      <p class='texto' >" +
            calle +
            num_exterior +
            num_interior +
            colonia.toLowerCase() +
            municipio.toLowerCase() +
            " <br>" +
            referencia +
            "</p>\n\
      <p class='titulo'><b>TELÉFONOS:</b></p>\n\
      <p><a href='tel:+" +
            telefono_uno +
            "'>" +
            telefono_uno +
            "</a>" +
            extension_uno +
            "</p>\n\n<p><a href='tel:+" +
            telefono_dos +
            "'>" +
            telefono_dos +
            "</a>" +
            extension_dos +
            "</a></p>\n\
      <p class='titulo'><b>HORARIOS DE ATENCIÓN:</b></p>\n\
      <p>" +
            dias_atencion +
            "</p>\n\
      <p>" +
            horario_ini +
            "</p>\n\
      <p class='titulo'><b>SERVICIOS:</b></p>\n\
      <p class='texto'>&nbsp;-&nbsp;" +
            servicios_.toLowerCase() +
            "</p>\n\
      ";

          L.marker([el.latitud, el.longitud], { icon: customMarker })
            .addTo(layerRef.current)
            .bindPopup(domicilio);

          console.log("layer ref", layerRef.current);
        });
      });
    }
    layerRef.current.clearLayers();
    poligonRef.current.clearLayers();
    layerRef.current.addTo(map);

    // Se pintan los poligonos
    const parksGeoJson = new L.GeoJSON(mapa, {
      style: {
        color: "#704074",
        opacity: 0.6,
        fillColor: "#fff",
        fillOpacity: 0,
      },

      onEachFeature: (feature = {}, layer) => {
        const { properties = {} } = feature;
        const { Name } = properties;
        if (!Name) return;
        layer.bindPopup(`<p>${Name}</p>`);
      },
    });
    parksGeoJson.addTo(poligonRef.current);

    poligonRef.current.addTo(map);
  }, [props.datos]);

  return (
    <div id="map" className="col-md-12 mt-3">
      <Map
        ref={mapRef}
        center={[19.3, -99.11914106215106]}
        minZoom={11}
        maxZoom={15}
        zoom={11}
      >
        <TileLayer
          url="https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png"
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        />
      </Map>
    </div>
  );
}

export default MapView;
